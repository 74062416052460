import logo from './logo.svg';
import './App.css';
import Form from './components/Form'
import LeftButton from './components/Menu';

function App() {
  return (
    <div className="App">
      <LeftButton/>
      <h1>Welcome to Elidiet</h1>
      
      <Form />
    </div>
  );
}

export default App;
