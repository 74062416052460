// src/components/Form.js
import React, { useState } from 'react';

function Form() {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [address, setAddress] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [sex, setSex] = useState('');
  const [occupation, setOccupation] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};

    // Check each field
    if (!firstname) newErrors.firstname = 'First name is required';
    if (!lastname) newErrors.lastname = 'Last name is required';
    if (!email) newErrors.email = 'Email is required';
    if (!phone) newErrors.phone = 'Phone number is required';
    if (!dob) newErrors.dob = 'Date of birth is required';
    if (!address) newErrors.address = 'Address is required';
    if (!height) newErrors.height = 'Height is required';
    if (!weight) newErrors.weight = 'Weight is required';
    if (!sex) newErrors.sex = 'Sex is required';
    if (!occupation) newErrors.occupation = 'Occupation is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // If no errors, proceed with form submission
      console.log('Form Data:', {
        firstname,
        lastname,
        email,
        phone,
        dob,
        address,
        height,
        weight,
        sex,
        occupation,
      });
      setIsSubmitted(true);
    }
  };

  if (isSubmitted) {
    return <div style={{textAlign: 'center'}}>Thank you for submitting! We will contact you shortly.</div>;
  }

  return (
    <div>
      <h2>Appointment Form</h2>
      <form onSubmit={handleSubmit}>
        {/* First Name */}
        <div>
          <label htmlFor="firstname">First Name:</label>
          <input
            type="text"
            id="firstname"
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
          />
          {errors.firstname && <span style={{color: 'red'}}>{errors.firstname}</span>}
        </div>

        {/* Last Name */}
        <div>
          <label htmlFor="lastname">Last Name:</label>
          <input
            type="text"
            id="lastname"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
          />
          {errors.lastname && <span style={{color: 'red'}}>{errors.lastname}</span>}
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <span style={{color: 'red'}}>{errors.email}</span>}
        </div>

        {/* Phone Number */}
        <div>
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {errors.phone && <span style={{color: 'red'}}>{errors.phone}</span>}
        </div>

        {/* Date of Birth */}
        <div>
          <label htmlFor="dob">Date of Birth:</label>
          <input
            type="date"
            id="dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
          {errors.dob && <span style={{color: 'red'}}>{errors.dob}</span>}
        </div>

        {/* Address (No Autocomplete) */}
        <div>
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter your address"
          />
          {errors.address && <span style={{color: 'red'}}>{errors.address}</span>}
        </div>

        {/* Height */}
        <div>
          <label htmlFor="height">Height (cm):</label>
          <input
            type="number"
            id="height"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          {errors.height && <span style={{color: 'red'}}>{errors.height}</span>}
        </div>

        {/* Weight */}
        <div>
          <label htmlFor="weight">Weight (kg):</label>
          <input
            type="number"
            id="weight"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          {errors.weight && <span style={{color: 'red'}}>{errors.weight}</span>}
        </div>

        {/* Sex (Dropdown) */}
        <div style={{padding: '10px 0px'}}>
          <label htmlFor="sex">Gender:</label>
          <select
            id="sex"
            value={sex}
            onChange={(e) => setSex(e.target.value)}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          {errors.sex && <span style={{color: 'red'}}>{errors.sex}</span>}
        </div>

        {/* Occupation */}
        <div>
          <label htmlFor="occupation">Occupation:</label>
          <input
            type="text"
            id="occupation"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          />
          {errors.occupation && <span style={{color: 'red'}}>{errors.occupation}</span>}
        </div>

        <button type="submit" style={{ padding: '10px 20px', marginTop: '10px' }}>Submit</button>
      </form>
    </div>
  );
}

export default Form;
