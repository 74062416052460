import React from 'react';
import './Menu.css';

const Menu = () => {
    return (
        <div className="Menu">
            <button className="left-button">
                Click Me
            </button>
        </div>
    );
};

export default Menu;
